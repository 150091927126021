<template>
  <b-container>
    <b-row class="mb-3 pt-3 d-flex justify-content-between text-start">
      <b-col><curva-title :title="$t('mainNav.videos')"></curva-title></b-col>
    </b-row>
    <b-row id="offers-list" class="mb-5">
      <b-col lg="3" v-for="(video, key) in videoList" :key="key">
        <a :href="`https://youtube.com/watch?v=${video.video}`" target="_blank">
          <iq-card class="text-white shadow-none iq-style1 iq-border-radius-5">
            <img :alt="video.name" :src="`https://img.youtube.com/vi/${video.video}/hqdefault.jpg`" class="w-100" height="240"/>
              <p class="p-2 px-3 font-size-14 text-start">{{video.name}}</p>
          </iq-card>
        </a>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col class="d-flex justify-content-center">
        <b-pagination
            v-if="rows > perPage"
            class="curva-pagination"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="offers-list"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import mediaServices from '../services/multimedia.services'
export default {
  props: {
  },
  data () {
    return {
      perPage: 8,
      currentPage: 1,
      rows: 3,
      videoList: []
    }
  },
  methods: {
    getMedia () {
      mediaServices.getMedia('video', this.currentPage).then(res => {
        this.videoList = res.data.data.data
        this.perPage = res.data.per_page
        this.currentPage = res.data.current_page
        this.rows = res.data.total
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getMedia()
  }
}
</script>
